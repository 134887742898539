export const AUTH0_CONFIG = {
  domain: 'dev-mnc55q4h7lg2jd33.us.auth0.com',
  clientId: 'dsrhVvIdrvwe0p9DBAS0gcL87RnW7W80',
  authorizationParams: {
    audience: 'http://127.0.0.1:5000',
    redirect_uri: window.location.origin,
  },
  apiUri: 'http://127.0.0.1:5000',
  appUri: window.location.origin,
  errorPath: '/error',
};