/**
 * Enum that represents the type of the alert
 */
export enum AlertType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

/**
 * Alert interface that is used to display messages to the user in the application
 * @property type - The type of the alert (success, error, info, warning)
 * @property message - The message to be displayed
 * @property id - The id of the alert
 */
export interface Alert {
    id: string;
    title: string;
    message: string;
    type: AlertType;
    isDeleted: boolean;
    isVisualized: boolean;
    createdAt: Date;
}
