import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { filter, flatMap, map, switchMap, tap } from 'rxjs';

export const profileCompletionNegativeGuard: CanActivateFn = (route, state) => {
  // console.log('guards profileCompletionNegativeGuard');
  const userService = inject(UserService);
  const router = inject(Router);
  return userService.user$.pipe(
    filter((user: User | null) => user !== null),
    switchMap((user: any) =>
      userService.isUserValid(user).pipe(
        tap((isValid: boolean) => {
          if (isValid) router.navigate(['/']);
        }),
        map((isValid: boolean) => !isValid)
      )
    )
  );
};
