import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { createMask } from '@ngneat/input-mask';
import {Observable, filter, first, of} from 'rxjs';
import { User, UserRole } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { fadeInAnimation } from 'src/app/shared/animation/fade-in.animation';
import { checkMobileMode } from 'src/app/utils/checkMobileMode';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [fadeInAnimation],
})
export class ProfileComponent {
  // Flag to determine whether the view is in mobile mode.
  isMobileMode: boolean = false;
  
  // The user's profile information.
  user: User | null = null;
  userRole = UserRole;

  // A copy of the user's profile data for editing.
  newUser: User | null = null;

  registerPortoCardUrl = environment.registerPortoCardUrl

  inputMaskPhoneNumber = createMask({
    alias: '(+351) 999 999 999',
    parser: (value: string) => value.split('').filter(d => '0123456789'.includes(d)).join(''),
  });

  inputMaskPortoCard = createMask({
    alias: '99 999',
    digitsOptional: false,
    parser: (value: string) => value.split('').filter(d => '0123456789'.includes(d)).join(''),
  });

  inputMaskNif = createMask({
    alias: '999 999 999',
    digitsOptional: false,
    parser: (value: string) => value.split('').filter(d => '0123456789'.includes(d)).join(''),
  });

  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // Initially check the screen size on component initialization.
    this.isMobileMode = checkMobileMode();
    this.userService.user$.subscribe((user) => {
      this.user = user;
      if (!user) return;
      this.newUser = new User({
        email: user.email,
        externalId: user.externalId,
        name: user.name || '',
        id: user.id,
        isRegistered: user.isRegistered,
        phoneNumber: user.phoneNumber || '',
        portoCard: user.portoCard || '',
        type: user.type,
        picture: user.picture,
        nif: user.nif,
        fixed_start_location: user.fixed_start_location,
        logo: user.logo
      }
      );
    });
  }

  // check if user is valid
  isUserValid(user: User | null): Observable<boolean> {
    return of(true);
    return this.userService.isUserValid(this.user);
  }

  // Navigate to the booking page.
  goToHome() {
    this.router.navigate(['/']);
  }

  // Sign out the user.
  signOut() {
    this.authService.signOut();
  }

  isNameValid(): boolean{
    return this.userService.isNameValid(this.newUser?.name);
  }

  isPhoneNumberValid(): boolean{
    return this.userService.isPhoneNumberValid(this.newUser?.phoneNumber);
  }

  isEmailValid(): boolean{
    return this.userService.isEmailValid(this.newUser?.email);
  }

  isPortoCardValid(): boolean{
    return this.userService.isPortoNumberValid(this.newUser?.portoCard);
  }

  isNifValid(): boolean{
    return this.userService.isNifValid(this.newUser?.nif);
  }

  updateUser(){
    this.userService.userUpdate(this.newUser!).subscribe((user: User) => {
      this.userService.user$.next(user);
      this.goToApp();
    });
  }

  private goToApp(){
    this.router.navigate(['/app']);
  }


  /**
   * Format the user's name to display it in the profile view.
   * @returns The first name and the last name.
   */
  formatUserName(): string{
    if(!this.user || !this.user.name) return '';
    
    const names = this.user.name.split(' ');
    if(names?.length === 0) return names[0];

    return names[0] + ' ' + names[names.length - 1];
  }
}
