import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailComponent),
      multi: true,
    },
  ],
})
export class InputEmailComponent implements ControlValueAccessor {
  @Input() disabled: boolean = false
  @Input() value: string = ''
  @Input() label: string = 'Email'
  @Input() showError: boolean = false
  @Input() errorMessage: string = ''
  @Input() isMandatory: boolean = false

  emailInputMask = createMask({ alias: 'email' })
  emailFC = new FormControl('')
  isFocused: boolean = false

  writeValue(value: any): void {
    // Handle writing a value to your component
    if (value !== undefined) {
      this.value = value
    }
  }

  registerOnChange(fn: any): void {
    // Register a callback function to be called when the model changes
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    // Register a callback function to be called when the component is touched
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if you want to handle disabling the component
  }

  // Callback functions to be used within your component

  private onChange: (value: any) => void = () => {}
  private onTouched: () => void = () => {}

  // When the input value changes
  onInputChange(value: any) {
    this.value = value // Update the internal value
    this.onChange(value) // Notify ngModel of the change
  }
}
