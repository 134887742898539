<div class="payment-status-container">
    <div class="card">
        <div *ngIf="payment" class="card-body">
            <!-- PAYMEN PENDING -->
            <div *ngIf="[paymentStatus.Pending, paymentStatus.Submitted].includes(payment.status)">
                <app-pending-card-body [payment]="payment"></app-pending-card-body>
            </div>

            <!-- PAYMENT AUTHORIZED -->
            <div *ngIf="[paymentStatus.Success].includes(payment.status)">
                <app-authorized-card-body></app-authorized-card-body>
            </div>

            <!-- PAYMENT CANCELED -->
            <div *ngIf="![paymentStatus.Pending, paymentStatus.Submitted, paymentStatus.Success].includes(payment.status)" class="payment-rejected">
                <app-canceled-card-body [booking]="booking"></app-canceled-card-body>
            </div>
        </div>
    </div>
</div>