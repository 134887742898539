import { Component } from '@angular/core';

@Component({
  selector: 'app-not-fount',
  templateUrl: './not-fount.component.html',
  styleUrls: ['./not-fount.component.css']
})
export class NotFountComponent {

}
