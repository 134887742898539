<div class="mb-3">
    <!-- Label of the field -->
    <label class="form-label">
        <span> {{label}} </span>
        <span *ngIf="isMandatory" class="text-danger">*</span>
    </label>

    <!-- Input field -->
    <div class="input-group">
        <span class="input-group-text input-label">P</span>
        <input *ngIf="isDisabled" type="text" class="form-control filter-invert-1" placeholder="" maxlength="14" [value]="value"
            disabled>
        <input *ngIf="!isDisabled" type="text" class="form-control filter-invert-1" placeholder="" maxlength="14" [ngModel]="value"
            (ngModelChange)="onInputChange($event)" [inputMask]="inputMask" (focus)="isFocused=true"
            (blur)="onBlur($event)">
    </div>

    <!-- Error message -->
    <div *ngIf="!isFocused && showError" class="text-danger">
        {{errorMessage}}
    </div>
</div>