/**
 * Interface representing a taxi booking.
 */
import { Companion } from "./companion.model";
import { Point } from "./point.model";
import { User } from "./user.model";

/**
 * Enumeration representing the state of a taxi booking.
 */
export enum BookingState {
  PENDING = 'Pending',
  AUTHORIZED = 'Authorized',
  CANCELED = 'Canceled',
  BOOK = 'Book',
  ENDED = 'Ended',
  IN_PROGRESSING = 'InProgressing',
  BOOKED = "Booked"
}

/**
 * @description
 * Interface to represent a booking of a taxi, including user and location details.
 */
export interface Booking {
  /** Unique identifier for the booking. */
  id: string;

  /** User who made the booking. */
  bookedBy: User;

  /** User for whom the taxi is booked. */
  user: User;

  /** Optional companion accompanying the user. */
  companion?: Companion;

  /** Starting location for the taxi ride. */
  startLocation?: Point;

  /** Ending location for the taxi ride. */
  endLocation?: Point;

  /** Current state of the booking. */
  state: BookingState;

  /** Date and time when the booking was made. */
  date: Date;

  /** Date and time when the booking was created. */
  createdDate: Date;

  /** NIF (Tax Identification Number) associated with the booking. */
  nif: string;

  /** Phone number associated with the booking. */
  phoneNumber: string;

  /** Date and time when the booking was last modified. */
  modified: Date;

  /** Number of passengers for the taxi ride. */
  nPassengers: number;

  clientName: string;
}
