import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  // console.log('guards authGuard');
  if (authService.userAuthToken$.value != '') return true;
  return authService.signIn().pipe(
    tap((isAuthenticated) => {
      if (!isAuthenticated) router.navigate(['/']);
    })
  );
};
