
<div *ngIf="user" ngbDropdown class="d-inline-block">
  <button type="button" class="btn-user-profile no-arrow" id="dropdownUser" ngbDropdownToggle>
    <img [src]="user.picture ? user.picture : '/assets/img/user-profile.png'" alt="" referrerpolicy="no-referrer">
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownUser" class="drop-menu-options-container">
    <span ngbDropdownItem>
      <i class="fa fa-envelope p-1" aria-hidden="true"></i>
      {{user.email}}
    </span>
    <button ngbDropdownItem (click)="goToProfile()">
      <i class="fa fa-user p-1" aria-hidden="true"></i>
      Perfil
    </button>
    <button class="btn text-danger" ngbDropdownItem (click)="logout()">
      <i class="fa fa-sign-out p-1"></i>
      Logout
    </button>
  </div>
</div>
