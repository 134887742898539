import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
  ],
})
export class InputPasswordComponent implements ControlValueAccessor {
  @Input() value: string = '';
  showPassword: boolean = false;

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  writeValue(value: any): void {
    // Handle writing a value to your component
  }

  registerOnChange(fn: any): void {
    // Register a callback function to be called when the model changes
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Register a callback function to be called when the component is touched
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if you want to handle disabling the component
  }

  // ...

  // Callback functions to be used within your component

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  // When the input value changes
  onInputChange(value: any) {
    this.value = value; // Update the internal value
    this.onChange(value); // Notify ngModel of the change
  }
}
