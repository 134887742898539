// auth.interceptor.ts
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { jwtDecode } from 'jwt-decode';
import {endpointsNoLogin}  from '../constants/endpoints-no-login'

/**
 * AuthInterceptor handles authentication-related tasks for outgoing HTTP requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor of AuthInterceptor.
   * @param authService - An instance of the AuthService for managing user authentication.
   * @param apiService - An instance of the ApiService containing API configuration details.
   */
  constructor (private authService: AuthService, private apiService: ApiService) { }


  /**
   * Intercepts outgoing HTTP requests and decides how to handle them based on the request URL.
   * @param req - The outgoing HttpRequest.
   * @param next - The HttpHandler for the next interceptor in the chain.
   * @returns An Observable of the HttpEvent.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (!req.url.includes(this.apiService.baseUrlValue) && !req.url.includes(this.apiService.baseUrlValueNew) ) {
      return this.outserverRequest(req, next);
    }

    if(endpointsNoLogin.includes(req.url)) return this.outserverRequest(req, next);

    if (!req.url.includes('token/refresh')) {
      return this.requestNormal(req, next);
    }

    return this.requestRefreshedToken(req, next);
  }

  /**
   * Handles requests that are not directed towards the API server.
   * @param req - The outgoing HttpRequest.
   * @param next - The HttpHandler for the next interceptor in the chain.
   * @returns An Observable of the HttpEvent.
   */
  outserverRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
  }

  /**
   * Handles requests directed towards the API server but excluding 'token/refresh'.
   * Retrieves an updated authentication token from the AuthService and attaches it to the request headers.
   * @param req - The outgoing HttpRequest.
   * @param next - The HttpHandler for the next interceptor in the chain.
   * @returns An Observable of the HttpEvent.
   */
  requestNormal(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getUpdatedAuthToken().pipe(switchMap(token => {
      if(token){
        req = this.addAuthTokenRequest(req, token);
      }
      return next.handle(req)
    }));
  }

  /**
   * Handles requests directed towards the API server with 'token/refresh' in the URL.
   * Retrieves the user authentication token from the AuthService and attaches it to the request headers.
   * @param req - The outgoing HttpRequest.
   * @param next - The HttpHandler for the next interceptor in the chain.
   * @returns An Observable of the HttpEvent.
   */
  requestRefreshedToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService.userAuthToken$.subscribe(token => {
      const decoded: any = jwtDecode(token);
    })

    return this.authService.userAuthToken$.pipe(
      switchMap(token => {
        if (token){
          req = this.addAuthTokenRequest(req, token);
        }
        return next.handle(req)
      })
    );
  }

  /**
  * Adds an authentication token to the headers of an HttpRequest.
  *
  * This method takes an HttpRequest and a valid authentication token, then creates and returns a
  * clone of the original request with the specified token added to the 'Authorization' header.
  *
  * @param req - The original HttpRequest to be cloned and modified.
  * @param token - The valid authentication token to be added to the request headers.
  * @returns A cloned HttpRequest with the 'Authorization' header set to the provided token.
  */
  private addAuthTokenRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `${token}`,
      },
    });
  }

}
