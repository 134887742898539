<app-loading></app-loading>
<router-outlet></router-outlet>

<ng-template #modalAcceptCookies let-modal>
	<div class="modal-header">
		<h4 class="modal-title">ESTE SITE UTILIZA COOKIES</h4>
	</div>
	<div class="modal-body">
		<p>Este site utiliza cookies para permitir o seu correto funcionamento e melhorar a sua experiência de navegação. Ao navegar no site está a concordar com a Política de cookies.</p>
        <p>Para mais informações consulte a nossa <a class="btn text-secondary border-secondary-bottom p-0" href="https://www.stcpservicos.pt/politica-de-cookies">Política de cookies</a>.</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="acceptCookies()">Concordar</button>
	</div>
</ng-template>



