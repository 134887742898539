import { Injectable } from '@angular/core';
import { Companion } from '../models/companion.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CompanionService {

  constructor() { }

  createCompanionFromRequest(companionObject: any): Companion {
    if (!companionObject) {
      return {
        name: '',
        portoCard: ''
      }
    }
    return {
      name: companionObject['name'],
      portoCard: companionObject['porto_card']
    }
  }

  createCompanionFromUser(user: User): Companion {
    return {
      name: user.name + '',
      portoCard: user.portoCard + ''
    }
  }
}
