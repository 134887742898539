// custom-number.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '../models/booking.model';

@Pipe({
  name: 'booking',
})
export class BookingPipe implements PipeTransform {
  transform(booking: Booking|null): string {
    if(!booking) return 'No booking';
    return `
        Booking: ${booking.id} | 
        CreatedDate: ${booking.createdDate.toDateString()} | 
        User: ${booking.user.name + ' - ' + booking?.user.email}
        PaymentStatus: ${booking.state}
        `;
  }
}