<div class="input-group form-control p-0">
  <input type="password" class="form-control border-0" [type]="showPassword ? 'text' : 'password'"
    [ngModel]="value" (ngModelChange)="onInputChange($event)"
    placeholder="Password" />
  <div class="input-group-append">
    <button class="btn text-secondary border-0" type="button" (click)="togglePasswordVisibility()">
      <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
    </button>
  </div>
</div>
