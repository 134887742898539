<div class="mb-3">
    <!-- Label of the field -->
    <label class="form-label">
        <span> {{label}} </span>
        <span *ngIf="isMandatory" class="text-danger">*</span>
    </label>

    <!-- Input field -->
    <div class="input-group">
        <input placeholder="___ ___ ___" *ngIf="isDisabled" disabled type="text" [class]="'form-control blur-3 ' + (invertColor ? 'filter-invert-1' : '')" [value]="value">
        <input placeholder="___ ___ ___" *ngIf="!isDisabled" type="text" [class]="'form-control blur-3 ' + (invertColor ? 'filter-invert-1' : '')" [ngModel]="value"
            (ngModelChange)="onInputChange($event)" [inputMask]="inputMask" (blur)="onBlur($event)">
    </div>

    <!-- Error message -->
    <div *ngIf="!isFocused && showError" class="text-danger">
        {{errorMessage}}
    </div>
</div>