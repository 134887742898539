/**
 * @fileoverview
 * HTTP Interceptor for Angular to add 'X-Session' header from a 'session' parameter in cookies.
 * If 'session' parameter is not present, the header is omitted.
 */


import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../services/api.service';

/**
 * Interceptor to add 'X-Session' header to outgoing HTTP requests.
 */
@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
    constructor(private apiService: ApiService) {}

    /**
     * Intercept method to modify outgoing requests.
     * @param {HttpRequest<any>} request - Original HTTP request.
     * @param {HttpHandler} next - Next handler in the chain.
     * @returns {Observable<HttpEvent<any>>} - Observable of the modified HTTP event.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes(this.apiService.baseUrlValue)) return next.handle(request);

        const modifiedRequest = request.clone({
            withCredentials: true
        });

        return next.handle(modifiedRequest);

    }
}