import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { User, UserRole } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
})
export class TopMenuComponent {
  @Input() backgroundColor: string = 'transparent';


  
  isMenuCollapsed: boolean = true;
  isCollapsedProfile: boolean = true;
  user: User | null = null;
  userRole = UserRole;
  isMobile: boolean = false;
  // showElemnts: boolean = false;
  constructor(private userService: UserService, private router: Router,) {}
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  ngOnInit(): void {
    this.userService.user$.subscribe((user) => (this.user = user));
    this.checkScreenWidth();
    // setTimeout(() => {
    //   this.showElemnts = true;
    // }, 5000);
  }

  private checkScreenWidth() {
    this.isMobile = window.innerWidth < 992;
  }
  
  gotoHome() {
    this.router.navigate(['/']);
  }

  isHomePage(): boolean {
    return this.router.url === '/app/booking';
  }

  goBack() {
    window.history.back(); // Navigate back to the previous page
  }
}
