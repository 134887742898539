import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { createMask } from '@ngneat/input-mask'
import { COUNTRY_CODES } from '../../../constants/country-codes'
import { CountryCode } from 'src/app/models/country-code'
import { sanitizeHtml } from 'src/app/utils/sanitizeHtml'

@Component({
  selector: 'app-input-contact',
  templateUrl: './input-contact.component.html',
  styleUrls: ['./input-contact.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputContactComponent),
      multi: true,
    },
  ],
})
export class InputContactComponent implements OnInit, ControlValueAccessor {
  @Input() isDisabled: boolean = false;
  @Input() value: string = '';
  @Input() label: string = 'Número de Contato';
  @Input() showError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() isMandatory: boolean = false;
  @Input() invertColor: boolean = true;
  @Input() isInvertedLayoutColors: boolean = false;
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  countryCode: string = '351';
  countryCodes = COUNTRY_CODES;
  filteredCoundtryCode = COUNTRY_CODES;
  isFocused: boolean = false;
  countryCodeFilter: string = '';
  oldValue: string = '';

  inputMask = createMask({
    // alias: '999 999 999 9{0,3} 9{0,3} 9{0,3}',
    alias: '999 999 999',
    parser: (value: string) => {
      // const phone = this.countryCode.replace('+', '') + value.replace(/\D/g, '');
      const phone = value.replace(/\D/g, '');
      return phone;
    },
  });

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef){}

  ngOnInit(): void {
    this.oldValue = this.value;
    this.elementRef.nativeElement.addEventListener('paste', (event: ClipboardEvent) => this.onPaste(event));
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text').replace(/\D/g, '');
      this.onInputChange(pastedText);
    }
    this.cdr.detectChanges();
  }

  selectCountryCodeFromValue() {
    if (!this.value) return;
    for (let countryCode of this.countryCodes) {
      if (this.value.startsWith(countryCode.numeric)) {
        this.countryCode = countryCode.numeric;
        this.value = this.value.replace(countryCode.numeric, '');
        break;
      }
    }
  }

  filterCountryCode() {
    const textFilter = this.countryCodeFilter.toLowerCase().replace(/\s/g, '');
    this.filteredCoundtryCode = this.countryCodes.filter((countryCode) => {
      const countryCodeName = JSON.stringify(countryCode)
        .toLowerCase()
        .replace(/\s/g, '');
      return countryCodeName.includes(textFilter);
    });
  }

  countryCodeSelected(countryCode: CountryCode) {
    this.countryCode = countryCode.numeric;
    this.onBlur();
  }

  // Set the initial value using writeValue
  writeValue(value: any): void {
    // Handle writing a value to your component
    if (value !== undefined) {
      this.value = value;
      this.selectCountryCodeFromValue();
    }
  }

  registerOnChange(fn: any): void {
    // Register a callback function to be called when the model changes
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Register a callback function to be called when the component is touched
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if you want to handle disabling the component
  }

  // ...

  // Callback functions to be used within your component

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  // When the input value changes
  onInputChange(value: any) {
    this.value = value; // Update the internal value
    this.onChange(value); // Notify ngModel of the change
  }

  onBlur(event?: FocusEvent): void {
    if (this.countryCode + this.value === this.oldValue) return;
    this.blur.emit(event);
    this.oldValue = this.countryCode + this.value;
    // this.blur.emit(event);
  }

  // sanitizeErrorHtmlLocal(){
  //   return sanitizeHtml(this.errorMessage);
  // }
}
