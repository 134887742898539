/**
 * @description
 * Interface representing a payment.
 *
 * @property {number} amount - The amount of the payment.
 * @property {Date} expiryDate - The expiry date of the payment.
 * @property {string} id - Unique identifier for the payment.
 * @property {string[]} paymentMethods - List of payment methods used.
 * @property {string} spgContext - SPG (Secure Payment Gateway) context information.
 * @property {PaymentStatus} status - Status of the payment (e.g., Success, Declined, Pending).
 * @property {string} transactionID - Unique transaction identifier for the payment.
 */
export interface Payment {
  /** Amount of the payment. */
  amount: number;

  /** Expiry date of the payment. */
  expiryDate: Date;

  /** Unique identifier for the payment. */
  id: string;

  /** List of payment methods used. */
  paymentMethods: string[];

  /** SPG (Secure Payment Gateway) context information. */
  spgContext: string;

  /** Status of the payment (e.g., Success, Declined, Pending). */
  status: PaymentStatus;

  /** Unique transaction identifier for the payment. */
  transactionID: string;
  
  /** Unique transaction identifier for the payment. */
  referenceData?: {
    amount: number,
    entity: string,
    expireDate: Date,
    reference: string,
  };
}

/**
 * @description
 * Enumeration representing the status of a payment.
 */
export enum PaymentStatus {
  Declined = 'Declined',
  Expired = 'Expired',
  Refunded = 'Refunded',
  Timeout = 'Timeout',
  Canceled = 'Canceled',
  Pending = 'Pending',
  Failure = 'Failure',
  Success = 'Success',
  Submitted = 'Submitted',
}
