import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { Route, Router } from '@angular/router';
import { UserRole } from '../models/user.model';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY, AUTH_TYPE_LOCAL_STORAGE_KEY } from '../config/auth-config';

@Injectable({
  providedIn: 'root',
})
export class StcpLoginService {
  authToken$: BehaviorSubject<string> | null = null;
  isLoginIn: boolean = false;

  constructor(private api: ApiService, private router: Router) {}

  /**
   * Signs in the user by redirecting to the STCP login page.
   *
   * The user will be redirected back to the application after a successful login with the JWT token.
   * The JWT token will be stored in the service.
   */
  signIn(): void {
    const endPoint = '?sso';
    window.location.href = this.api.baseUrlValue + endPoint;
  }

  /**
   * Retrieves the authentication token, prioritizing extraction from the current URL,
   * and fallback to local storage if not found.
   *
   * This method first attempts to extract the authentication token from the current URL
   * using the `getAuthTokenFromUrl` function. If no token is found in the URL, it falls back
   * to retrieving the token from the local storage using the `getAuthTokenfromLocalStorage` function.
   * If a valid token is found, it is returned as an observable; otherwise, an observable with null is returned.
   *
   * @returns {Observable<string | null>} An observable emitting the authentication token or null if not found.
   */
  idToken(): Observable<string | null> {
    let token: string | null = this.getAuthTokenFromUrl();
    if (this.authToken$ && !token) {
      token = this.authToken$.getValue();
    }

    if (!token) {
      token = this.getAuthTokenfromLocalStorage();
    }

    if (!token) {
      return of('');
    }
    
    this.setAuthToken(token);
    return this.retrieveValidAuthtoken();
  }

  /**
   * Retrieves an authentication token from the current URL and stores it in the local storage.
   *
   * This function parses the current window's URL, extracts the authentication token from the query parameter
   * 'token', and stores it in the local storage using a specified key. If a token is found in the URL,
   * it is stored in the local storage, and the token is returned. If no token is found, the function returns null.
   *
   * @returns {string | null} The extracted authentication token, or null if no token is found in the URL.
   */
  private getAuthTokenFromUrl(): string | null {
    const url = window.location.href;
    const token = url.split('token=')[1];
    if (token) {
      localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
    }
    return token;
  }

  /**
   * Retrieves the authentication token from the local storage.
   *
   * This function retrieves the authentication token stored in the local storage using a specified key.
   * It checks for the presence of the token and ensures that it is not set to 'undefined'. If a valid token
   * is found, it is returned; otherwise, the function returns null.
   *
   * @returns {string | null} The authentication token retrieved from local storage, or null if no valid token is found.
   */
  private getAuthTokenfromLocalStorage(): string | null {
    const token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    if (token && token !== 'undefined') {
      return token;
    }
    return null;
  }

  /**
   * Retrieves a valid authentication token by making a POST request to the 'token/refresh' endpoint.
   *
   * This method sends a POST request to the 'token/refresh' endpoint using the ApiService's post method.
   * The response is expected to contain a valid authentication token, which is extracted and returned.
   * If the request is successful, the retrieved token is logged to the console for debugging purposes.
   *
   * @returns An Observable emitting a valid authentication token (string) or null if the request fails.
   */
  retrieveValidAuthtoken(): Observable<string | null> {
    const endpoint = 'token/refresh';
    return this.api.post(endpoint, {}).pipe(
      tap((res: any) => this.setAuthToken(res.token)),
      map((res: any) => res.token)
    );
  }

  /**
   * Signs out the user by clearing the JWT token.
   * 
   * This method triggers the Single Logout (SLO) process by redirecting the user to the 'slo' endpoint.
   * It also removes the authentication type and token from the local storage, effectively logging out the user.
   */
  signOut() {
    const endpoint = 'slo';
    localStorage.removeItem(AUTH_TYPE_LOCAL_STORAGE_KEY);
    localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    window.location.href = this.api.baseUrlValue + endpoint;
  }

  /**
   * Sets the authentication token in the service and stores it in the local storage.
   *
   * This method updates the authentication token in the BehaviorSubject `authToken$`
   * if it is present, allowing components to subscribe and react to changes in the authentication token.
   * Additionally, the authentication token is stored in the local storage using the key specified
   * in the constant `AUTH_TOKEN_LOCAL_STORAGE_KEY`. This ensures persistence across page reloads.
   *
   * @param {string} token - The authentication token to be set.
   */
  private setAuthToken(token: string) {
    this.authToken$?.next(token);
    localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
  }
}
