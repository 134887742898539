/**
 * A custom Angular pipe for formatting bank reference numbers.
 * This pipe takes a numeric or string input and formats it as a bank reference number.
 * It applies thousand separators and replaces commas with spaces.
 *
 * Usage:
 *   value | bankReference
 *
 * Example:
 *   {{ '1000000' | bankReference }} => '1 000 000'
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bankReference' })
export class BankReferencePipe implements PipeTransform {
  /**
   * Transforms a numeric or string value into a formatted bank reference number.
   * @param value The numeric or string value to be formatted.
   * @returns The formatted bank reference string.
   */
  transform(value: any): string {
    if (!value || isNaN(+value) || typeof value !== 'string') {
      return value; // Return unchanged if input is not valid
    }
    // const numberValue = +value; // Convert input string to number
    // Apply thousand separators and replace commas with spaces
    // return numberValue.toLocaleString('en-US').replace(new RegExp(',', 'g'), ' ');

    const formatedString = value.split('')
    .reverse()
    .reduce((b:string[][], c: string) => {
        const index = b.length - 1;
        if(b[index].length < 3) b[index].push(c)
        else b.push([c])
        return b
    }, [[]])
    .reduce((b, c) => b + ' ' + c.join(''), '')
    .split('')
    .reverse()
    .join('')
    .trim()

    return formatedString;
  }
}
