import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { sanitizeHtml } from 'src/app/utils/sanitizeHtml';

@Component({
  selector: 'app-input-nif',
  templateUrl: './input-nif.component.html',
  styleUrls: ['./input-nif.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNifComponent),
      multi: true,
    },
  ],
})
export class InputNifComponent implements ControlValueAccessor {
  @Input() isDisabled: boolean = false
  @Input() value: string = ''
  @Input() label: string = 'NIF'
  @Input() showError: boolean = false
  @Input() errorMessage: string = 'O campo <b>NIF</b> está incorreto'
  @Input() isMandatory: boolean = false
  @Input() placeholder: string = '';
  @Input() isInvertedLayoutColors: boolean = false;

  inputMaskNif = createMask({
    alias: '999 999 999',
    parser: (value: string) => value.split('').filter(d => '0123456789'.includes(d)).join(''),
  });

  isFocused: boolean = false

  writeValue(value: any): void {
    // Handle writing a value to your component
    if (value !== undefined) {
      this.value = value
    }
  }

  registerOnChange(fn: any): void {
    // Register a callback function to be called when the model changes
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    // Register a callback function to be called when the component is touched
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if you want to handle disabling the component
  }

  // Callback functions to be used within your component

  private onChange: (value: any) => void = () => {}
  private onTouched: () => void = () => {}

  // When the input value changes
  onInputChange(value: any) {
    this.value = value // Update the internal value
    this.onChange(value) // Notify ngModel of the change
  }

  sanitizeHtmlLocal(){
    return sanitizeHtml(this.errorMessage);
  }
}
