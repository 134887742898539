export const visaCarUsers = {
    'f531134e-f04b-41d1-844b-04ceb09f4258': {
        userId: 'f531134e-f04b-41d1-844b-04ceb09f4258',
        startPoint: {
            id: 'aa782a4f-9422-11ee-b02b-0242ac120002',
            lat: 37.07490593202228,
            lon: -8.116266268330552,
            name: 'Dom Pedro Portobelo',
            type: 'hotel',
            formattedAddress: 'Avenida Tivoli, Lote H5, 8125-410 Quarteira',
        }
    },
}