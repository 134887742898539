<div *ngIf="user" ngbDropdown class="d-inline-block alert-component-container">
    <!-- <button type="button" class="btn btn-sm border-0 p-0 btn-user-profile no-arrow" id="dropdownBasic1"
        ngbDropdownToggle>
        <i class="fa fa-bell"></i>
        <span *ngIf="alerts.length>9"class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
            9+<span class="visually-hidden">unread messages</span>
        </span>
        <span *ngIf="alerts.length<=9"class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
            {{alerts.length}}<span class="visually-hidden">unread messages</span>
        </span>
    </button> -->
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop-menu-options-container">
        <ul *ngIf="alerts.length > 0" class="list-group list-group-flush">
            <li *ngFor="let alert of alerts" class="list-group-item">
                <div class="alert-container">
                    <div class="item-icon-container">
                        <i *ngIf="alert.type == alertTypes.SUCCESS" class="fa fa-check-circle text-success" aria-hidden="true"></i>
                        <i *ngIf="alert.type == alertTypes.ERROR" class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i>
                        <i *ngIf="alert.type == alertTypes.INFO" class="fa fa-info-circle text-info" aria-hidden="true"></i>
                        <i *ngIf="alert.type == alertTypes.WARNING" class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                    </div>
                    <div class="item-text-container">
                        <!-- <h1>{{alert.title}}</h1> -->
                        <p class="item-text">{{alert.message}}</p>
                        <p class="item-type-text">
                            <span *ngIf="alert.type == alertTypes.SUCCESS"> Sucesso </span>
                            <span *ngIf="alert.type == alertTypes.ERROR"> Error </span>
                            <span *ngIf="alert.type == alertTypes.INFO"> Informativo</span>
                            <span *ngIf="alert.type == alertTypes.WARNING"> Alerta </span>
                        </p>
                        <p class="item-created-at">{{alert.createdAt.toLocaleDateString('pt-PT')}}</p>
                    </div>
                    <div class="item-delete-container">
                        <button class="btn btn-outline-danger btn-sm" (click)="deleteAlert(alert)">
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </li>
        </ul>

        <div *ngIf="alerts.length == 0" class="no-alert-container p-3">
            <span class="no-alert-text">No alerts</span>
        </div>

    </div>
</div>