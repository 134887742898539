import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { inject } from '@angular/core';
import { User } from '../models/user.model';
import { filter, map, switchMap, tap } from 'rxjs';

export const profileCompletionGuard: CanActivateFn = (route, state) => {
  // console.log('guards profileCompletionGuard');
  const userService = inject(UserService);
  const router = inject(Router);
  return userService.user$.pipe(
    filter((user: User | null) => user !== null),
    switchMap((user: User | null) =>
      userService.isUserValid(user).pipe(
        tap((isValid: boolean) => {
          if (!isValid) router.navigate(['/profile-completion']);
        })
      )
    )
  );
};
