import {
  EmailAuthProvider,
  GoogleAuthProvider,
  PhoneAuthProvider,
} from '@firebase/auth';

// PRODUCTION
// export const FIREBASE_CONFIG = {
//   apiKey: 'AIzaSyDUDG9frxCpLXX74xHgByfNEVpvmCBniUg',
//  // authDomain: 'stcpweb-dev.geolink.pt',
//   authDomain: "stcp-e4714.firebaseapp.com",
//   projectId: 'stcp-e4714',
//   storageBucket: 'stcp-e4714.appspot.com',
//   messagingSenderId: '1023938669759',
//   appId: '1:1023938669759:web:3e74a807bd764ba57caa0a',
//   measurementId: 'G-DEDJ6KEYYW',
// };

// DEVELOPMENT
export const FIREBASE_CONFIG = {
  // apiKey: "AIzaSyDUDG9frxCpLXX74xHgByfNEVpvmCBniUg",
  // authDomain: "stcp-e4714.firebaseapp.com",
  // projectId: "stcp-e4714",
  // storageBucket: "stcp-e4714.appspot.com",
  // messagingSenderId: "1023938669759",
  // appId: "1:1023938669759:web:2087330055cf6ad87caa0a",
  // measurementId: "G-RB4S96PGRM"

  // apiKey: "AIzaSyCIByki4ricMxu36PmX0C5_0B3TnTMucRs",
  // authDomain: "stcp-dev.firebaseapp.com",
  // projectId: "stcp-dev",
  // storageBucket: "stcp-dev.appspot.com",
  // messagingSenderId: "926014673261",
  // appId: "1:926014673261:web:6a9485196d7081e91eca64"

  apiKey: "AIzaSyCIByki4ricMxu36PmX0C5_0B3TnTMucRs",
  authDomain: "stcp-dev.firebaseapp.com",
  projectId: "stcp-dev",
  storageBucket: "stcp-dev.appspot.com",
  messagingSenderId: "926014673261",
  appId: "1:926014673261:web:6a9485196d7081e91eca64"

};

export const SIGN_IN_SUCCESS_URL = '/';

export const SIGN_IN_OPTIONS = [
  // EmailAuthProvider.PROVIDER_ID,
  // GoogleAuthProvider.PROVIDER_ID,
  // FacebookAuthProvider.PROVIDER_ID,
  // TwitterAuthProvider.PROVIDER_ID,
  // GithubAuthProvider.PROVIDER_ID,
  EmailAuthProvider.PROVIDER_ID,
  // PhoneAuthProvider.PROVIDER_ID,
  // AnonymousAuthProvider.PROVIDER_ID,
];