import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertContainerComponent } from './components/alert-container/alert-container.component';
import { ProfileButtonComponent } from './components/profile-button/profile-button.component';
import { TopMenuComponent } from './top-menu.component';
import { SearchBookingComponent } from './components/search-booking/search-booking.component';



@NgModule({
  declarations: [
    TopMenuComponent,
    ProfileButtonComponent,
    AlertContainerComponent,
    SearchBookingComponent,
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,
    RouterLink,
    NgbDropdownModule,
    RouterModule
  ],
  exports: [TopMenuComponent],
})
export class TopMenuModule {}
