import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { StcpAction } from 'src/app/models/stcp-action';
import { UserService } from 'src/app/services/user.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-input-porto-card',
  templateUrl: './input-porto-card.component.html',
  styleUrls: ['./input-porto-card.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPortoCardComponent),
      multi: true,
    },
  ],
})
export class InputPortoCardComponent implements ControlValueAccessor {
  @ViewChild('textInput') inputField!: ElementRef;


  @Input() isDisabled: boolean = false;
  @Input() value: string = '';
  @Input() label: string = 'Cartão Porto.';
  @Input() showError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() isMandatory: boolean = false;

  /**
   * @description
   * Validation status of the input field (valid, invalid, validating, none)
   */
  @Input() validationStatus: 'valid' | 'invalid' | 'validating' | 'none' =
    'none';
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  oldValue: string = '';

  isFocused: boolean = false;
  // Create the mask
  inputMask = createMask({
    alias: 'numeric',
    groupSeparator: ' ',
    digits: 0,
    parser: (value: string) => {
      const portoCard = value.replace(/\D/g, '')
        .padStart(11, '0')
        // .split('')
        // .reverse()
        // .join('');
      
      return portoCard;
    },
  });

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef){}

  ngOnInit(): void {
    this.oldValue = this.value;

    this.elementRef.nativeElement.addEventListener('paste', (event: ClipboardEvent) => this.onPaste(event));
  }

  // fromEvent(this.inputField.nativeElement, 'input')
  //     .pipe(
  //       debounceTime(500), // Adjust the debounce time as needed (in milliseconds)
  //       distinctUntilChanged()
  //     )
  //     .subscribe(() => {
  //       // This function will be called after the user stops typing
  //       console.log('User stopped typing. Value:', this.inputField.nativeElement.value);
  //       // Call your desired function or perform the desired action here
  //     });




  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = parseInt(clipboardData.getData('text').replace(/\D/g, ''));
      this.onInputChange(pastedText);
    }
    this.cdr.detectChanges();
  }



  // Set the initial value using writeValue
  writeValue(value: any): void {
    // Handle writing a value to your component
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    // Register a callback function to be called when the model changes
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Register a callback function to be called when the component is touched
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if you want to handle disabling the component
  }

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  // When the input value changes
  onInputChange(value: any) {
    this.value = value; // Update the internal value
    this.onChange(value); // Notify ngModel of the change
  }

  onBlur(event: FocusEvent): void {
    // Add your logic here to handle the blur event.
    // You can access the event object and the element that triggered the blur event.
    // const stcpAction: StcpAction = {
    //   portoCard: this.value,
    //   contact: '',
    //   email: '',
    // };

    this.isFocused = false;
    this.showError = false;
    
    
    if (this.value === this.oldValue) return;
    this.blur.emit(event);
    this.oldValue = this.value;

    // this.userService
    //   .portoCardCheck(stcpAction)
    //   .subscribe(({ portocard, success }) => {
    //     if (!portocard || portocard.erro) {
    //       this.isValid = false;
    //       this.showError = true;
    //       if (portocard?.mensagem_erro)
    //         this.errorMessage = portocard?.mensagem_erro;
    //     } else {
    //       this.isValid = true;
    //       this.showError = false;
    //     }
    //   });
  }
}
