import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Subscription, debounceTime, take } from 'rxjs';
import { AUTH_TYPE_LOCAL_STORAGE_KEY } from 'src/app/config/auth-config';
import { StcpAction } from 'src/app/models/stcp-action'
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { BookingService } from 'src/app/services/booking.service';
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'app-stcp-action-form',
  templateUrl: './stcp-action-form.component.html',
  styleUrls: ['./stcp-action-form.component.css'],
})
export class StcpActionFormComponent {
  @Input() stcpAction: StcpAction = {
    portoCard: '',
    contact: '',
    email: '',
    companion: false,
  };
  @Input() showButtons: boolean = true;
  @Input() disableAll: boolean = false;
  @Input() isMandatory: boolean = true;
  @Input() user?: User;

  @Input() cleanButtonLabel: string = 'Limpar';

  errorMessage: string = '';

  portoCardValidationStatus: 'valid' | 'invalid' | 'validating' | 'none' =
    'none';

  dataSubscription?: Subscription;

  @Output() confirmHandler: EventEmitter<User> =
    new EventEmitter<User>();

  @Output() cancelHandler: EventEmitter<void> =
    new EventEmitter<void>();

  showErrors: boolean = false;
  // user?: User;

  constructor(
    private userService: UserService,
    private api: ApiService
  ) { }

  confirmAction() {
    // if (!this.user) return;

    this.checkPortoCard()
  }

  cleanFieldsHandler() {
    if (this.disableAll) return;
    this.stcpAction = {
      portoCard: '',
      contact: '',
      email: '',
    };

    this.cancelHandler.emit();
  }

  showErrorsHandler() {
    this.showErrors = true;
  }

  checkPortoCard() {
    this.dataSubscription = this.userService
      .portoCardCheck(this.stcpAction, this.user)
      .subscribe((data) => {
        const { error, user: userData } = data;
        if (error) {
          this.portoCardValidationStatus = 'invalid';
          this.errorMessage = error.mensagem_erro;
          return;
        }
        
        this.user = this.userService.createUserFromRequest(userData);
        this.portoCardValidationStatus = 'valid';
        this.confirmHandler.emit(this.user);
      });
  }
}
