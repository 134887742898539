// custom-number.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portoCard',
})
export class PortoCard implements PipeTransform {
  transform(value: string): string {
    value = value.padStart(11, '0')
    const formattedNumber = `P${value.slice(0, 2)} ${value.slice(2, 5)} ${value.slice(5, 8)} ${value.slice(8)}`;
    return formattedNumber;
  }
}