import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.css'],
})
export class ProfileButtonComponent {
  @Input() user: User | null = null;

  constructor(private auth: AuthService, private router: Router) {
  }

  logout(): void {
    this.auth.signOut();
  }

  goToProfile(){
    this.router.navigate(['/app/profile']);
  }
}
