import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Booking, BookingState } from 'src/app/models/booking.model';
import { Payment, PaymentStatus } from 'src/app/models/payment.model';
import { User } from 'src/app/models/user.model';
import { BookingService } from 'src/app/services/booking.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sibs-payment-status',
  templateUrl: './sibs-payment-status.component.html',
  styleUrls: ['./sibs-payment-status.component.css']
})
export class SibsPaymentStatusComponent implements OnDestroy {
  @Input() payment?: Payment;
  @Input() bookingId?: string;
  booking?: Booking;
  bookingLoopTime?: any;
  bookingStates = BookingState;
  paymentStatus = PaymentStatus;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService:UserService,
    private bookingService: BookingService
    ){}
    
  ngOnInit(){
    if(!this.bookingId) return;
    this.selectBooking(this.bookingId)
    // this.getBookingLoop(this.bookingId);
  }

  ngOnDestroy(): void {
    clearInterval(this.bookingLoopTime);
  }

  selectBooking(boolingId?: string): any{
    const user = this.userService.user$.getValue()
    if(!boolingId || !user) return this.goToHome();
    // this.userService.user$
    //   .pipe(filter(user => !!user))
    //   .subscribe((user: User|null) => {
      this.bookingService
        .retrieveBookingByUserAndBookinId(user!, boolingId).subscribe((res) => {
          this.booking = res.booking
          if(![this.bookingStates.PENDING, this.bookingStates.IN_PROGRESSING].includes(this.booking.state)) {
            clearInterval(this.bookingLoopTime);
          }
        });
    // })
  }

  goToHome(){
    return this.router.navigate(['/']);
  }
}
