import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Payment } from 'src/app/models/payment.model';

@Component({
  selector: 'app-pending-card-body',
  templateUrl: './pending-card-body.component.html',
  styleUrls: ['./pending-card-body.component.css']
})
export class PendingCardBodyComponent {
  @Input() payment?: Payment;


  constructor(private router: Router, private modalService: NgbModal) { }

  goToHome(){
    this.modalService.dismissAll();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 500);
  }
}
