<div class="mb-3">
    <!-- Label of the field -->
    <label class="form-label">
      <span> {{label}} </span>
      <span *ngIf="isMandatory" class="text-danger">*</span>
    </label>
  
    <!-- Input field -->
    <div class="input-group">
      <input *ngIf="!isDisabled" [class]="'form-control ' + (isInvertedLayoutColors ? 'filter-invert-1' : '')" [ngModel]="value" (ngModelChange)="onInputChange($event)"
        [placeholder]="placeholder || '___ ___ ___'" [inputMask]="inputMaskNif"/>
      <input *ngIf="isDisabled" [class]="'form-control ' + (isInvertedLayoutColors ? 'filter-invert-1' : '')" disabled [placeholder]="placeholder || '___ ___ ___'" [ngModel]="value" [inputMask]="inputMaskNif" />
    </div>
  
    <!-- Error message -->
    <div *ngIf="!isFocused && showError" class="text-danger">
      {{sanitizeHtmlLocal()}}
    </div>
  </div>