import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { User, UserRole } from '../models/user.model';
import { AuthSystem } from '../models/auth-system';
import { AuthWithFirebase } from '../models/auth-firebase';
import { AuthWithStcp } from '../models/auth-stcp';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { AUTH_TYPE_LOCAL_STORAGE_KEY } from '../config/auth-config';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authSystem: AuthSystem;

  isAuthenticatedValue: boolean | null = null;

  constructor(
    private router: Router,
    private userService: UserService,
    private api: ApiService
    ) {
    this.authSystem = this.createAuthSystemInstance();
    this.authSystem.userAuthToken$.subscribe(token => this.userService.token$.next(token));
    this.api.logoutHandler = () => {
      this.signOut();
    }
    this.userService.signOut = () => {
      this.signOut();
    }
  }

  createAuthSystemInstance(): AuthSystem {
    let authType = localStorage.getItem(AUTH_TYPE_LOCAL_STORAGE_KEY);
    if(window.location.href.includes('?token=')){
      authType = UserRole.CLIENT + '';
    }

    switch (authType) {
    
      case UserRole.OPERATOR:
        return new AuthWithFirebase();
      case UserRole.CONTROLCENTER:
        return new AuthWithFirebase();
      case UserRole.VISA_CAR_OPERATOR:
        return new AuthWithFirebase();
      case UserRole.CLIENT:
        return new AuthWithStcp();
      default:
        return new AuthWithFirebase();
    }
  }

  get userAuthToken$(): BehaviorSubject<string> {
    return this.userService.token$;
  }

  get user$(): BehaviorSubject<User | null> {
    return this.userService.user$;
  }

  signIn(): Observable<boolean> {
    return this.authSystem.signIn();
  }

  signOut(): void {
    this.authSystem.signOut();
    
  }

  isAuthTokenValid(): boolean{
    return this.authSystem.isAuthTokenValid();
  }

  getUpdatedAuthToken(): Observable<string | null>{
    const isAuthenticatedValue = this.isAuthTokenValid();
    if(!isAuthenticatedValue){
      // alert('stop');
      return this.authSystem.idToken().pipe(tap(token => {
        if (!token) this.authSystem.userAuthToken$.next('');
        this.authSystem.userAuthToken$.next(token!);
      }));
    }

    return of(this.authSystem.userAuthToken$.getValue());
  }
}
