import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authorized-card-body',
  templateUrl: './authorized-card-body.component.html',
  styleUrls: ['./authorized-card-body.component.css']
})
export class AuthorizedCardBodyComponent {
  constructor(private router: Router){}
  
  goToHome(){
    this.router.navigate(['/app'])
  }
}
