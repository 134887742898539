<div class="header">
    <i class="fa fa-lock header-icon shadow-animation" aria-hidden="true"></i>
</div>

<div *ngIf="payment && payment.referenceData" class="multbanco-container p-3">
    <h5 class="card-title text-primary_">Pagamento por Multbanco</h5>
    <h6 class="card-title text-primary_">Aguardando Pagamento</h6>
    <div class="multibanco-data py-3">
        <div class="multbanco-image-container">
            <img src="/assets/img/icons-png/multbanco-icon.png" alt="">
        </div>
        
        <table class="multbanco-info-container">
            <tr>
                <td class="text-bold">Entidade</td>
                <td>:</td>
                <td class="text-left px-1">{{payment.referenceData.entity}}</td>
            </tr>
            <tr>
                <td class="text-bold">Referência</td>
                <td>:</td>
                <td class="text-left px-1">{{payment.referenceData.reference | bankReference}}</td>
            </tr>
            <tr>
                <td class="text-bold">Montante</td>
                <td>:</td>
                <td class="text-left px-1">{{payment.referenceData.amount | currencyEuro}}</td>
            </tr>
            <tr>
                <td class="text-bold">Data de Expiração</td>
                <td>:</td>
                <td class="text-left px-1">{{payment.referenceData.expireDate | date: 'yyyy-MM-dd hh:mm'}}</td>
            </tr>
        </table>

    </div>
    <button class="btn btn-primary mt-3" type="button" (click)="goToHome()">Voltar ao menu principal</button>
</div>
    
<h5 *ngIf="payment && !payment.referenceData"  class="card-title text-primary">Processando Pagamento...</h5>
