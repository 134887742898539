import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SibsPaymentStatusComponent } from './sibs-payment-status.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PendingCardBodyComponent } from './components/pending-card-body/pending-card-body.component';
import { CanceledCardBodyComponent } from './components/canceled-card-body/canceled-card-body.component';
import { AuthorizedCardBodyComponent } from './components/authorized-card-body/authorized-card-body.component';
import { DefaultCardBodyComponent } from './components/default-card-body/default-card-body.component';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    SibsPaymentStatusComponent,
    PendingCardBodyComponent,
    CanceledCardBodyComponent,
    AuthorizedCardBodyComponent,
    DefaultCardBodyComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
  ],
  exports: [SibsPaymentStatusComponent],
})
export class SibsPaymentStatusModule { }
