import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserRole } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search-booking',
  templateUrl: './search-booking.component.html',
  styleUrls: ['./search-booking.component.css'],
})
export class SearchBookingComponent {
  user: User | null = null;
  userRole: any = UserRole;
  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(){
    this.userService.user$.subscribe(user => this.user = user!); 
  }

  goToSearchBooking(){
    this.router.navigate(['app/operator-search-booking']);
  }
}
