import { inject } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from '../config/auth-config';
import { UserRole } from './user.model';
import { jwtDecode } from 'jwt-decode';
import { AuthSystem } from './auth-system';
import { StcpLoginService } from '../services/stcp-login.service';

/**
 * @description
 * STCP authentication system implementation extending AuthSystem.
 *
 * This class provides methods to interact with the STCP authentication service
 * and extends the base AuthSystem for common authentication functionality.
 */
export class AuthWithStcp extends AuthSystem {
  /** Indicates whether a login attempt is in progress. */
  isLoginIn: boolean = false;

  /** Reference to the StcpLoginService for STCP authentication. */
  override service: StcpLoginService = inject(StcpLoginService);

  /**
   * Constructs an instance of AuthWithStcp.
   */
  constructor() {
    super();
    this.service.authToken$ = this.userAuthToken$;
  }

  /**
   * Overrides the signIn method from AuthSystem.
   * @returns An Observable<boolean> indicating the success of the sign-in operation.
   */
  override signIn(): Observable<boolean> {
    if (this.isLoginIn) return of(true);
    
    return this.service.idToken().pipe(
      switchMap(token => {
        this.isLoginIn = true;
        
        if (!!token) {
          this.setAuthType(UserRole.CLIENT);
          this.retrieveUserFromLogin();
          return of(true);
        } else {
          this.userAuthToken$.next('');
          this.signOut();
          return of(false);
        }
      })
    );
  }

  /**
   * Overrides the signOut method from AuthSystem.
   * Signs out the user and performs necessary cleanup.
   */
  override signOut(): void {
    this.isLoginIn = false;
    this.service.signOut();
    this.userAuthToken$.next('');
    this.removeAuthType();
    this.user$.next(null);
  }

  /**
   * Overrides the isAuthTokenValid method from AuthSystem.
   * Checks if the stored authentication token is still valid.
   * @returns A boolean indicating whether the authentication token is valid.
   */
  override isAuthTokenValid(): boolean {
    const token = this.userAuthToken$.getValue();
    const decoded: any = jwtDecode(token);

    if (!decoded || !decoded.exp) return false;

    const expiredDate = new Date(decoded.exp * 1000);
    const isValid = expiredDate.getTime() > new Date().getTime();

    return isValid;
  }

  /**
   * Overrides the idToken method from AuthSystem.
   * @returns An Observable<string | null> representing the authentication token.
   */
  override idToken(): Observable<string | null> {
    return this.service.idToken();
  }
}
