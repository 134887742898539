import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Alert, AlertType } from 'src/app/models/alert.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.css'],
})
export class AlertContainerComponent {
  @Input() user: User | null = null;
  alerts: Alert[] = [];
  alertTypes = AlertType;

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.userService.userAlerts$.subscribe((alerts) => this.alerts = alerts);
  }

  deleteAlert(alert: Alert){
    alert.isDeleted = true;
    this.alerts = this.alerts.filter((a) => !a.isDeleted);
  }
}
