<div class="mb-3">
  <!-- Label of the field -->
  <label class="form-label">
    <span> {{label}} </span>
    <span *ngIf="isMandatory" class="text-danger">*</span>
  </label>

  <!-- Input field -->
  <div class="input-group">
    <input *ngIf="!disabled" class="form-control" type="email" [ngModel]="value" (ngModelChange)="onInputChange($event)"
      [inputMask]="emailInputMask" placeholder="_@_._" />
    <input *ngIf="disabled" class="form-control" type="email" disabled placeholder="_@_._" />
  </div>

  <!-- Error message -->
  <div *ngIf="!isFocused && showError" class="text-danger">
    {{errorMessage}}
  </div>
</div>