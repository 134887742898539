/**
 * A custom Angular pipe for formatting numbers into Euro currency.
 * This pipe takes a numeric input and formats it as a Euro currency value.
 * The currency symbol (€) is appended after the number.
 *
 * Usage:
 *   value | currencyEuro
 *
 * Example:
 *   {{ 10 | currencyEuro }} => 10.00€
 *   {{ 1 | currencyEuro }} => 1.00€
 *   {{ 2 | currencyEuro }} => 2.00€
 *   {{ 0.5 | currencyEuro }} => 0.50€
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyEuro' })
export class CurrencyEuroPipe implements PipeTransform {
  /**
   * Transforms a numeric value into Euro currency format.
   * @param value The numeric value to be formatted.
   * @returns The formatted currency string.
   */
  transform(value: number): string {
    if (isNaN(value)) {
      return '';
    }
    // Ensure two decimal places
    const formattedValue = value.toFixed(2).replace('.', ',');
    return `${formattedValue}€`;
  }
}