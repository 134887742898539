import { Component, Input } from '@angular/core';

export enum IconColors {
  White = 'white',
  Primary = '#5EDDC1',
  Secondary = '#8A79CB',
  Success = '#2ecc71',
  Danger = '#e53639',
  Warning = '#f39c12',
  Info = '#48d6ba',
  Light = 'white',
  Dark = 'black',
}

export type IconNames  = 'exclamation-cloud' | 'map' | 'origem-destination' | 'taxi';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent {
  @Input() iconName: IconNames = 'exclamation-cloud';
  @Input() size: number = 18;
  @Input() color: IconColors = IconColors.White;
  @Input() class: string = '';
  @Input() style: string = '';

  svgContent: string = '';

  private basePath = 'assets/img/icons/';

  createPath(): string {
    return `${this.basePath}${this.iconName}.svg`;
  }

  calculateSize(): string {
    return `width: ${this.size}px;`;
  }



}