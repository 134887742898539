import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent  implements AfterViewInit  {
  @ViewChild('modalAcceptCookies') modalAcceptCookies?: TemplateRef<any>;

  title = 'STCP Serviços';
  localStorageAcceptCookiesKey: string = 'stcp-accept-cookies';

  constructor(private modalService: NgbModal) {
   
   }
  ngAfterViewInit(): void {
    const stcpAcceptCookies = localStorage.getItem(this.localStorageAcceptCookiesKey);
    if(!stcpAcceptCookies){
      // this.openShowAcceptCookiesModal();
    }
  }

  openShowAcceptCookiesModal() {
    const modalOptions:NgbModalOptions = {
      centered: true,
      size: 'md',
      backdrop:'static',
      keyboard: false
    }

		this.modalService.open(this.modalAcceptCookies, modalOptions).result.then(
      () => {},
      (closeReason) => {
        this.acceptCookies();
      });
	}

  acceptCookies() {
    localStorage.setItem(this.localStorageAcceptCookiesKey, 'true');
    this.modalService.dismissAll();
  }
}
