import { CommonModule, CurrencyPipe, DecimalPipe, NgFor } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { InputMaskModule } from '@ngneat/input-mask';
import { AlertContainerComponent } from './components/top-menu/components/alert-container/alert-container.component';
import { InputContactComponent } from './components/input-contact/input-contact.component';
import { InputEmailComponent } from './components/input-email/input-email.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputPortoCardComponent } from './components/input-porto-card/input-porto-card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ProfileButtonComponent } from './components/top-menu/components/profile-button/profile-button.component';
import { StcpActionFormComponent } from './components/stcp-action-form/stcp-action-form.component';
import { TopMenuModule } from './components/top-menu/top-menu.module';
import { InsertDateComponent } from '../modules/dashboard/booking-management/components/insert-date/insert-date.component';
import { InputNifComponent } from './components/input-nif/input-nif.component';
import { PortoCard } from '../pipes/porto-card-pipe';
import { BookingPipe } from '../pipes/booking-pip';
import { SibsCheckoutComponent } from './components/sibs-checkout/sibs-checkout.component';
import { SibsPaymentStatusModule } from './components/sibs-payment-status/sibs-payment-status.module';
import { FooterComponent } from './components/footer/footer.component';
import { IconComponent } from './components/icon/icon.component';
import { RouterLink } from '@angular/router';
import { CurrencyEuroPipe } from '../pipes/currency-euro.pipe';
import { PipesModule } from '../pipes/pipes.module';


const COMPONENTS = [
  InputPasswordComponent,
  InputEmailComponent,
  LoadingComponent,
  InputPortoCardComponent,
  InputContactComponent,
  StcpActionFormComponent,
  InputNifComponent,
  PortoCard,
  BookingPipe,
  SibsCheckoutComponent,
  // SibsPaymentStatusModule
  FooterComponent,
  IconComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    InputMaskModule,
    DecimalPipe,
    NgFor,
    NgbModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbCollapseModule,
    TopMenuModule,
    RouterLink,
  ],
  exports: [COMPONENTS, TopMenuModule, SibsPaymentStatusModule, PipesModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        /* any shared services you want to provide */
      ],
    };
  }
}
