const BASE_URL = document.getElementsByTagName('base')[0].href;


export const SIBS_CONFIG = {
    "paymentMethodList": [
        "CARD",
        "MBWAY",
        "REFERENCE"
    ],
    "amount": {
        "value": 1.00,
        "currency": "EUR"
    },
    "language": "pt",
    "redirectUrl": `${BASE_URL}/app/booking-management?op=1`,
    "customerData": null
}

export const SIBS_STYLE = {
    "layout": "default",
    "theme": "default",
    "color": {
        "primary": "#5EDDC1",
        "secondary": "#5EDDC1",
        "border": "",
        "surface": "",
        "header": {
            "text": "",
            "background": ""  
        },
        "body": {
            "text": "",
            "background": ""
        } 
    },
    "font": ""
}