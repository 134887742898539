import { inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AUTH_TYPE_LOCAL_STORAGE_KEY } from '../config/auth-config';
import { User, UserRole } from './user.model';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { of } from 'rxjs';

/**
 * @description
 * Abstract class for authentication systems.
 *
 * This abstract class defines the structure and behavior of authentication systems
 * and provides common functionality required by concrete authentication implementations.
 */
export abstract class AuthSystem {
    /** Reference to the ApiService for making API requests. */
    api: ApiService = inject(ApiService);

    /** Reference to the UserService for managing user-related data. */
    userService: UserService = inject(UserService);

    /** Represents the specific authentication service used (concrete implementation). */
    service: any = null;

    /** BehaviorSubject holding the user's authentication token. */
    userAuthToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    /** BehaviorSubject holding the user object or null if not authenticated. */
    user$: BehaviorSubject<User | null> = new BehaviorSubject<any>(null);

    /**
     * Constructs an instance of AuthSystem.
     */
    constructor() {}

    /**
     * Abstract method for signing in the user.
     * @returns An Observable<boolean> indicating the success of the sign-in operation.
     */
    abstract signIn(): Observable<boolean>;

    /**
     * Abstract method for signing out the user.
     */
    abstract signOut(): void;

    /**
     * Abstract method for checking if the authentication token is valid.
     * @returns A boolean indicating whether the authentication token is valid.
     */
    abstract isAuthTokenValid(): boolean;

    /**
     * Abstract method for retrieving the authentication token.
     * @returns An Observable<string | null> representing the authentication token.
     */
    abstract idToken(): Observable<string | null>;

    /**
     * Sets the authentication type in local storage.
     * @param authType The user's authentication role.
     */
    setAuthType(authType: UserRole): void {
        localStorage.setItem(AUTH_TYPE_LOCAL_STORAGE_KEY, authType); 
    }

    /**
     * Removes the authentication type from local storage.
     */
    removeAuthType(): void {
        localStorage.removeItem(AUTH_TYPE_LOCAL_STORAGE_KEY);
    }

    /**
     * Retrieves user data from the login process.
     * If an error occurs during retrieval, signs the user out.
     */
    retrieveUserFromLogin() {
        try {
            return this.userService.retrieveUserFromLogin();
        } catch (err) {
            this.signOut();
        }
    }

    retrieveUserFromLogin2(): Promise<User | null> {
        try {
            return this.userService.retrieveUserFromLogin2().then(user => {
                return user;
            }).catch(err => {
                this.signOut();
                return null; 
            });
        } finally {
            
        }
    }

    
}
