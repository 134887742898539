import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SIBS_CONFIG, SIBS_STYLE } from 'src/app/constants/sibs-config';
import { Payment } from 'src/app/models/payment.model';
import { User } from 'src/app/models/user.model';
import { environment as env } from '../../../../environment/environment';

@Component({
  selector: 'app-sibs-checkout',
  templateUrl: './sibs-checkout.component.html',
  styleUrls: ['./sibs-checkout.component.css']
})
export class SibsCheckoutComponent {
  spgStyle: string = '';

  @Input() id: string = '';
  @Input() payment?: Payment;
  @Input() user?: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.user) {
      this.createCheckoutWithUser(this.user);
    }
    else{
      this.createScheckoutWithoutUser();
    }
    
  }

  private createCheckoutWithUser(user: User){
    if(!this.payment) return;

    const spgConfig = this.createSpgConfig(this.id, this.payment)
    const spgStyle = this.createSpgStyle();

    this.createFormTag(this.payment.spgContext, spgConfig, spgStyle);
    this.createScriptTag(this.payment.transactionID);
  }

  private createScheckoutWithoutUser(): void{
    this.route.queryParams.subscribe(params => {
      if(!this.payment) return;

      const spgConfig = this.createSpgConfig(this.id, this.payment)
      const spgStyle = this.createSpgStyle();

      this.createFormTag(this.payment.spgContext, spgConfig, spgStyle);
      this.createScriptTag(this.payment.transactionID);
    });
  }

  createScriptTag(transactionID: string){
    const script = this.renderer.createElement('script');
    script.src = env.sibsUrl + transactionID;
    // script.src = `https://spg.qly.site1.sibs.pt/assets/js/widget.js?id=${transactionID}`;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }

  createFormTag(spgContext: string, spgConfig: string, spgStyle: string){
    const form = this.renderer.createElement('form');
    this.renderer.addClass(form, 'paymentSPG');

    // Add attributes dynamically
    this.renderer.setAttribute(form, 'spg-context', spgContext);
    this.renderer.setAttribute(form, 'spg-config', spgConfig);
    this.renderer.setAttribute(form, 'spg-style', spgStyle);

    // Append form to the host element
    // this.renderer.appendChild(this.el.nativeElement, form);

     // Get the element with id 'cardBody'
     const cardBodyElement = this.el.nativeElement.querySelector('#card-body');

     // Append form to the 'cardBody' element
     this.renderer.appendChild(cardBodyElement, form);
  }

  createSpgConfig(id: string, payment: Payment): string{
    const config = JSON.parse(JSON.stringify(SIBS_CONFIG));
    config.paymentMethodList = payment.paymentMethods;
    
    config["redirectUrl"]+= '&bookingId='+id;
    config["redirectUrl"]+= '&paymentId='+payment.id;
    config.amount = payment.amount;
    
    return JSON.stringify(config);
  }

  createSpgStyle(): string{
    const stype = JSON.parse(JSON.stringify(SIBS_STYLE));
    return JSON.stringify(stype);
  }

  
}
