import { CountryCode } from "src/app/models/country-code";

export const COUNTRY_CODES: CountryCode[] = [
  {
    country: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    numeric: '004',
  },
  {
    country: 'Albania',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    numeric: '008',
  },
  {
    country: 'Algeria',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    numeric: '012',
  },
  {
    country: 'American Samoa',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    numeric: '016',
  },
  {
    country: 'Andorra',
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    numeric: '020',
  },
  {
    country: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    numeric: '024',
  },
  {
    country: 'Anguilla',
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    numeric: '660',
  },
  {
    country: 'Antarctica',
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    numeric: '010',
  },
  {
    country: 'Antigua and Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    numeric: '028',
  },
  {
    country: 'Argentina',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    numeric: '032',
  },
  {
    country: 'Armenia',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    numeric: '051',
  },
  {
    country: 'Aruba',
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    numeric: '533',
  },
  {
    country: 'Australia',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    numeric: '036',
  },
  {
    country: 'Austria',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    numeric: '040',
  },
  {
    country: 'Azerbaijan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    numeric: '031',
  },
  {
    country: 'Bahamas (the)',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    numeric: '044',
  },
  {
    country: 'Bahrain',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    numeric: '048',
  },
  {
    country: 'Bangladesh',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    numeric: '050',
  },
  {
    country: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    numeric: '052',
  },
  {
    country: 'Belarus',
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    numeric: '112',
  },
  {
    country: 'Belgium',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    numeric: '056',
  },
  {
    country: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    numeric: '084',
  },
  {
    country: 'Benin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    numeric: '204',
  },
  {
    country: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    numeric: '060',
  },
  {
    country: 'Bhutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    numeric: '064',
  },
  {
    country: 'Bolivia (Plurinational State of)',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    numeric: '068',
  },
  {
    country: 'Bonaire',
    alpha2Code: 'Sint Eustatius and Saba',
    alpha3Code: 'BQ',
    numeric: 'BES',
  },
  {
    country: 'Bosnia and Herzegovina',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    numeric: '070',
  },
  {
    country: 'Botswana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    numeric: '072',
  },
  {
    country: 'Bouvet Island',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    numeric: '074',
  },
  {
    country: 'Brazil',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    numeric: '076',
  },
  {
    country: 'British Indian Ocean Territory (the)',
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    numeric: '086',
  },
  {
    country: 'Brunei Darussalam',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    numeric: '096',
  },
  {
    country: 'Bulgaria',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    numeric: '100',
  },
  {
    country: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    numeric: '854',
  },
  {
    country: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    numeric: '108',
  },
  {
    country: 'Cabo Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    numeric: '132',
  },
  {
    country: 'Cambodia',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    numeric: '116',
  },
  {
    country: 'Cameroon',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    numeric: '120',
  },
  {
    country: 'Canada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    numeric: '124',
  },
  {
    country: 'Cayman Islands (the)',
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    numeric: '136',
  },
  {
    country: 'Central African Republic (the)',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    numeric: '140',
  },
  {
    country: 'Chad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    numeric: '148',
  },
  {
    country: 'Chile',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    numeric: '152',
  },
  {
    country: 'China',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    numeric: '156',
  },
  {
    country: 'Christmas Island',
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    numeric: '162',
  },
  {
    country: 'Cocos (Keeling) Islands (the)',
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    numeric: '166',
  },
  {
    country: 'Colombia',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    numeric: '170',
  },
  {
    country: 'Comoros (the)',
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    numeric: '174',
  },
  {
    country: 'Congo (the Democratic Republic of the)',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    numeric: '180',
  },
  {
    country: 'Congo (the)',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    numeric: '178',
  },
  {
    country: 'Cook Islands (the)',
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    numeric: '184',
  },
  {
    country: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    numeric: '188',
  },
  {
    country: 'Croatia',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    numeric: '191',
  },
  {
    country: 'Cuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    numeric: '192',
  },
  {
    country: 'Curaçao',
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    numeric: '531',
  },
  {
    country: 'Cyprus',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    numeric: '196',
  },
  {
    country: 'Czechia',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    numeric: '203',
  },
  {
    country: "Côte d'Ivoire",
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    numeric: '384',
  },
  {
    country: 'Denmark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    numeric: '208',
  },
  {
    country: 'Djibouti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    numeric: '262',
  },
  {
    country: 'Dominica',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    numeric: '212',
  },
  {
    country: 'Dominican Republic (the)',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    numeric: '214',
  },
  {
    country: 'Ecuador',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    numeric: '218',
  },
  {
    country: 'Egypt',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    numeric: '818',
  },
  {
    country: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    numeric: '222',
  },
  {
    country: 'Equatorial Guinea',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    numeric: '226',
  },
  {
    country: 'Eritrea',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    numeric: '232',
  },
  {
    country: 'Estonia',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    numeric: '233',
  },
  {
    country: 'Eswatini',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    numeric: '748',
  },
  {
    country: 'Ethiopia',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    numeric: '231',
  },
  {
    country: 'Falkland Islands (the) [Malvinas]',
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    numeric: '238',
  },
  {
    country: 'Faroe Islands (the)',
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    numeric: '234',
  },
  {
    country: 'Fiji',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    numeric: '242',
  },
  {
    country: 'Finland',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    numeric: '246',
  },
  {
    country: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    numeric: '250',
  },
  {
    country: 'French Guiana',
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    numeric: '254',
  },
  {
    country: 'French Polynesia',
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    numeric: '258',
  },
  {
    country: 'French Southern Territories (the)',
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    numeric: '260',
  },
  {
    country: 'Gabon',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    numeric: '266',
  },
  {
    country: 'Gambia (the)',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    numeric: '270',
  },
  {
    country: 'Georgia',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    numeric: '268',
  },
  {
    country: 'Germany',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    numeric: '276',
  },
  {
    country: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    numeric: '288',
  },
  {
    country: 'Gibraltar',
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    numeric: '292',
  },
  {
    country: 'Greece',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    numeric: '300',
  },
  {
    country: 'Greenland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    numeric: '304',
  },
  {
    country: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    numeric: '308',
  },
  {
    country: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    numeric: '312',
  },
  {
    country: 'Guam',
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    numeric: '316',
  },
  {
    country: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    numeric: '320',
  },
  {
    country: 'Guernsey',
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    numeric: '831',
  },
  {
    country: 'Guinea',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    numeric: '324',
  },
  {
    country: 'Guinea-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    numeric: '624',
  },
  {
    country: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    numeric: '328',
  },
  {
    country: 'Haiti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    numeric: '332',
  },
  {
    country: 'Heard Island and McDonald Islands',
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    numeric: '334',
  },
  {
    country: 'Holy See (the)',
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    numeric: '336',
  },
  {
    country: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    numeric: '340',
  },
  {
    country: 'Hong Kong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    numeric: '344',
  },
  {
    country: 'Hungary',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    numeric: '348',
  },
  {
    country: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PT',
    numeric: '351',
  },
  {
    country: 'Iceland',
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    numeric: '352',
  },
  {
    country: 'India',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    numeric: '356',
  },
  {
    country: 'Indonesia',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    numeric: '360',
  },
  {
    country: 'Iran (Islamic Republic of)',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    numeric: '364',
  },
  {
    country: 'Iraq',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    numeric: '368',
  },
  {
    country: 'Ireland',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    numeric: '372',
  },
  {
    country: 'Isle of Man',
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    numeric: '833',
  },
  {
    country: 'Israel',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    numeric: '376',
  },
  {
    country: 'Italy',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    numeric: '380',
  },
  {
    country: 'Jamaica',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    numeric: '388',
  },
  {
    country: 'Japan',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    numeric: '392',
  },
  {
    country: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    numeric: '832',
  },
  {
    country: 'Jordan',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    numeric: '400',
  },
  {
    country: 'Kazakhstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    numeric: '398',
  },
  {
    country: 'Kenya',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    numeric: '404',
  },
  {
    country: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    numeric: '296',
  },
  {
    country: "Korea (the Democratic People's Republic of)",
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    numeric: '408',
  },
  {
    country: 'Korea (the Republic of)',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    numeric: '410',
  },
  {
    country: 'Kuwait',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    numeric: '414',
  },
  {
    country: 'Kyrgyzstan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    numeric: '417',
  },
  {
    country: "Lao People's Democratic Republic (the)",
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    numeric: '418',
  },
  {
    country: 'Latvia',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    numeric: '428',
  },
  {
    country: 'Lebanon',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    numeric: '422',
  },
  {
    country: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    numeric: '426',
  },
  {
    country: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    numeric: '430',
  },
  {
    country: 'Libya',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    numeric: '434',
  },
  {
    country: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    numeric: '438',
  },
  {
    country: 'Lithuania',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    numeric: '440',
  },
  {
    country: 'Luxembourg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    numeric: '442',
  },
  {
    country: 'Macao',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    numeric: '446',
  },
  {
    country: 'Madagascar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    numeric: '450',
  },
  {
    country: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    numeric: '454',
  },
  {
    country: 'Malaysia',
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    numeric: '458',
  },
  {
    country: 'Maldives',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    numeric: '462',
  },
  {
    country: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    numeric: '466',
  },
  {
    country: 'Malta',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    numeric: '470',
  },
  {
    country: 'Marshall Islands (the)',
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    numeric: '584',
  },
  {
    country: 'Martinique',
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    numeric: '474',
  },
  {
    country: 'Mauritania',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    numeric: '478',
  },
  {
    country: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    numeric: '480',
  },
  {
    country: 'Mayotte',
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    numeric: '175',
  },
  {
    country: 'Mexico',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    numeric: '484',
  },
  {
    country: 'Micronesia (Federated States of)',
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    numeric: '583',
  },
  {
    country: 'Moldova (the Republic of)',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    numeric: '498',
  },
  {
    country: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    numeric: '492',
  },
  {
    country: 'Mongolia',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    numeric: '496',
  },
  {
    country: 'Montenegro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    numeric: '499',
  },
  {
    country: 'Montserrat',
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    numeric: '500',
  },
  {
    country: 'Morocco',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    numeric: '504',
  },
  {
    country: 'Mozambique',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    numeric: '508',
  },
  {
    country: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    numeric: '104',
  },
  {
    country: 'Namibia',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    numeric: '516',
  },
  {
    country: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    numeric: '520',
  },
  {
    country: 'Nepal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    numeric: '524',
  },
  {
    country: 'Netherlands (the)',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    numeric: '528',
  },
  {
    country: 'New Caledonia',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    numeric: '540',
  },
  {
    country: 'New Zealand',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    numeric: '554',
  },
  {
    country: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    numeric: '558',
  },
  {
    country: 'Niger (the)',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    numeric: '562',
  },
  {
    country: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    numeric: '566',
  },
  {
    country: 'Niue',
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    numeric: '570',
  },
  {
    country: 'Norfolk Island',
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    numeric: '574',
  },
  {
    country: 'Northern Mariana Islands (the)',
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    numeric: '580',
  },
  {
    country: 'Norway',
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    numeric: '578',
  },
  {
    country: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    numeric: '512',
  },
  {
    country: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    numeric: '586',
  },
  {
    country: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    numeric: '585',
  },
  {
    country: 'Palestine',
    alpha2Code: 'State of',
    alpha3Code: 'PS',
    numeric: 'PSE',
  },
  {
    country: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    numeric: '591',
  },
  {
    country: 'Papua New Guinea',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    numeric: '598',
  },
  {
    country: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    numeric: '600',
  },
  {
    country: 'Peru',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    numeric: '604',
  },
  {
    country: 'Philippines (the)',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    numeric: '608',
  },
  {
    country: 'Pitcairn',
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    numeric: '612',
  },
  {
    country: 'Poland',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    numeric: '616',
  },
  // {
  //   country: 'Portugal',
  //   alpha2Code: 'PT',
  //   alpha3Code: 'PRT',
  //   numeric: '620',
  // },
  {
    country: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    numeric: '630',
  },
  {
    country: 'Qatar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    numeric: '634',
  },
  {
    country: 'Republic of North Macedonia',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    numeric: '807',
  },
  {
    country: 'Romania',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    numeric: '642',
  },
  {
    country: 'Russian Federation (the)',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    numeric: '643',
  },
  {
    country: 'Rwanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    numeric: '646',
  },
  {
    country: 'Réunion',
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    numeric: '638',
  },
  {
    country: 'Saint Barthélemy',
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    numeric: '652',
  },
  {
    country: 'Saint Helena',
    alpha2Code: 'Ascension and Tristan da Cunha',
    alpha3Code: 'SH',
    numeric: 'SHN',
  },
  {
    country: 'Saint Kitts and Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    numeric: '659',
  },
  {
    country: 'Saint Lucia',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    numeric: '662',
  },
  {
    country: 'Saint Martin (French part)',
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    numeric: '663',
  },
  {
    country: 'Saint Pierre and Miquelon',
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    numeric: '666',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    numeric: '670',
  },
  {
    country: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    numeric: '882',
  },
  {
    country: 'San Marino',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    numeric: '674',
  },
  {
    country: 'Sao Tome and Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    numeric: '678',
  },
  {
    country: 'Saudi Arabia',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    numeric: '682',
  },
  {
    country: 'Senegal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    numeric: '686',
  },
  {
    country: 'Serbia',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    numeric: '688',
  },
  {
    country: 'Seychelles',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    numeric: '690',
  },
  {
    country: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    numeric: '694',
  },
  {
    country: 'Singapore',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    numeric: '702',
  },
  {
    country: 'Sint Maarten (Dutch part)',
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    numeric: '534',
  },
  {
    country: 'Slovakia',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    numeric: '703',
  },
  {
    country: 'Slovenia',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    numeric: '705',
  },
  {
    country: 'Solomon Islands',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    numeric: '090',
  },
  {
    country: 'Somalia',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    numeric: '706',
  },
  {
    country: 'South Africa',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    numeric: '710',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    numeric: '239',
  },
  {
    country: 'South Sudan',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    numeric: '728',
  },
  {
    country: 'Spain',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    numeric: '724',
  },
  {
    country: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    numeric: '144',
  },
  {
    country: 'Sudan (the)',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    numeric: '729',
  },
  {
    country: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    numeric: '740',
  },
  {
    country: 'Svalbard and Jan Mayen',
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    numeric: '744',
  },
  {
    country: 'Sweden',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    numeric: '752',
  },
  {
    country: 'Switzerland',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    numeric: '756',
  },
  {
    country: 'Syrian Arab Republic',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    numeric: '760',
  },
  {
    country: 'Taiwan (Province of China)',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    numeric: '158',
  },
  {
    country: 'Tajikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    numeric: '762',
  },
  {
    country: 'Tanzania',
    alpha2Code: 'United Republic of',
    alpha3Code: 'TZ',
    numeric: 'TZA',
  },
  {
    country: 'Thailand',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    numeric: '764',
  },
  {
    country: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    numeric: '626',
  },
  {
    country: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    numeric: '768',
  },
  {
    country: 'Tokelau',
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    numeric: '772',
  },
  {
    country: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    numeric: '776',
  },
  {
    country: 'Trinidad and Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    numeric: '780',
  },
  {
    country: 'Tunisia',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    numeric: '788',
  },
  {
    country: 'Turkey',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    numeric: '792',
  },
  {
    country: 'Turkmenistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    numeric: '795',
  },
  {
    country: 'Turks and Caicos Islands (the)',
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    numeric: '796',
  },
  {
    country: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    numeric: '798',
  },
  {
    country: 'Uganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    numeric: '800',
  },
  {
    country: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    numeric: '804',
  },
  {
    country: 'United Arab Emirates (the)',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    numeric: '784',
  },
  {
    country: 'United Kingdom of Great Britain and Northern Ireland (the)',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    numeric: '826',
  },
  {
    country: 'United States Minor Outlying Islands (the)',
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    numeric: '581',
  },
  {
    country: 'United States of America (the)',
    alpha2Code: 'US',
    alpha3Code: 'USA',
    numeric: '840',
  },
  {
    country: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    numeric: '858',
  },
  {
    country: 'Uzbekistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    numeric: '860',
  },
  {
    country: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    numeric: '548',
  },
  {
    country: 'Venezuela (Bolivarian Republic of)',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    numeric: '862',
  },
  {
    country: 'Viet Nam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    numeric: '704',
  },
  {
    country: 'Virgin Islands (British)',
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    numeric: '092',
  },
  {
    country: 'Virgin Islands (U.S.)',
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    numeric: '850',
  },
  {
    country: 'Wallis and Futuna',
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    numeric: '876',
  },
  {
    country: 'Western Sahara',
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    numeric: '732',
  },
  {
    country: 'Yemen',
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    numeric: '887',
  },
  {
    country: 'Zambia',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    numeric: '894',
  },
  {
    country: 'Zimbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    numeric: '716',
  },
  {
    country: 'Åland Islands',
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    numeric: '248',
  },
];