import { Component, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Booking } from 'src/app/models/booking.model';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-canceled-card-body',
  templateUrl: './canceled-card-body.component.html',
  styleUrls: ['./canceled-card-body.component.css']
})
export class CanceledCardBodyComponent {
  @Input() booking?: Booking;

  constructor(private router: Router, private modalService: NgbModal){}
  
  goToHome(){
    this.modalService.dismissAll();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 500);
  }

  goToBooking(){
    // parameter that indicate to open payment status modal
    const paramToRemove = 'op'

    // Get the current URL
    const currentUrl = this.router.url;

    // Parse the URL to extract query parameters
    const urlTree = this.router.parseUrl(currentUrl);
    const queryParams = { ...urlTree.queryParams };

    // Remove the desired parameter
    delete queryParams[paramToRemove];

    // Construct the updated URL without the removed parameter
    const updatedUrl = this.router.createUrlTree([urlTree.root], {queryParams}).toString();

    // Navigate to the updated URL
    this.router.navigateByUrl(updatedUrl);
  }
}
