import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent {
  isLoading: boolean = false;
  countValue: number = 0;

  constructor(private loadingService: LoadingService) {
    this.loadingService.loadingCount$.subscribe((value) => {
      // console.log('LOAD VALUE', value);
      this.isLoading = value > 0;
      this.countValue = value;
    });
  }
}
